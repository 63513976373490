.LoaderCard{
    position: absolute; 
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background-color: #fff;
    opacity: 0.8;
    img{ 
        position: absolute; 
        top: 40%;
        left: 40%;
         height: 200px;
         width: 200px;
         z-index: 10090;

    }
}
